import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

// Assuming these environment variables are set in your .env file
const LOGIN_PROTOCOL = process.env.REACT_APP_LOGIN_PROTOCOL;
const HOST_NAME = process.env.REACT_APP_LOGIN_HOST;

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    // Redirect to dashboard if already logged in
    useEffect(() => {
        // Check if the user has a valid token
        const token = localStorage.getItem('token');
        if (token) {
            validateToken(token);
        }
    }, [navigate]);

    const validateToken = async (token) => {
        try {
            const response = await fetch(`${LOGIN_PROTOCOL}://${HOST_NAME}/api/validate_token`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (response.ok) {
                navigate('/dashboard');
            } else {
                console.log("respose:", response.status);
                // If the token is invalid or expired
                localStorage.removeItem('token');
                // Optionally, redirect to login or show a message
                navigate('/login')
            }
        } catch (error) {
            console.error('Error validating token:', error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const loginUrl = `${LOGIN_PROTOCOL}://${HOST_NAME}/api/login`;
            console.log("Attempting login to:", loginUrl); // Log the login URL

            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'  // Important: Include cookies in the request
            });

            console.log("Response status:", response.status); // Log the response status
    
            if (response.ok) {
                const { token } = await response.json();
                localStorage.setItem('token', token); // Store the token
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                console.error('Login failed');
                // Optionally, handle and display login errors here
            }
        } catch (error) {
            console.error("There was an error logging in:", error);
            // Optionally, handle and display login errors here
        }
    };

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
            <Navbar />
            <form className="form-signin" onSubmit={handleLogin}>
                <h1 className="h3 mb-3 font-weight-normal text-center">Please sign in</h1>
                <label htmlFor="inputUsername" className="sr-only">Username</label>
                <input
                    type="text"
                    id="inputUsername"
                    className="form-control"
                    placeholder="Username"
                    required autoFocus
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <input
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            </form>
        </div>
    );
}

export default Login;
