// GalleryModal.js

import React, { useState, useEffect } from 'react';

function GalleryModal({ images }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Add a resize event listener to update the 'isMobile' state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    console.log(images);

    return (
        <div>
            <div className="row">
                {images.map((img, index) => (
                    <div 
                        className="col-md-3 mb-3" 
                        key={index} 
                        onClick={() => { 
                            if (!isMobile) {  // Only set the selected image if it's not mobile
                                setSelectedImage(img); 
                                setActiveIndex(index);
                            }
                        }}
                    >
                        <img src={img} alt={`gallery-thumb-${index}`} className={`img-thumbnail ${activeIndex === index ? 'active' : ''}`} />
                    </div>
                ))}
            </div>

            {selectedImage && (
                <div className="modal" onClick={() => { setSelectedImage(null); setActiveIndex(-1); }}>
                    <img src={selectedImage} alt="selected" className="img-fluid"/>
                </div>
            )}
        </div>
    );
}

export default GalleryModal;

