import PricingCard from "./PricingCard";
function Pricing() {
    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">Pricing</h2>
            <div className="row">
                <div className="col-md-1"></div> {/* Empty column */}
                <div className="col-md-4">
                    <PricingCard 
                        ageGroup="Under 9 (Including 9)" 
                        price="$480"
                        duration="Each class is 1.5 hours long."
                        offer="First trial class is FREE for new students."
                        unit="10 classes"
                    />
                </div>
                <div className="col-md-2"></div> {/* Empty column */}
                <div className="col-md-4">
                    <PricingCard 
                        ageGroup="Older than 9" 
                        price="$580"
                        duration="Each class is 1.5 hours long."
                        offer="First trial class is FREE for new students."
                        unit="10 classes"
                    />
                </div>
                <div className="col-md-1"></div> {/* Empty column */}
            </div>
            <div className="row mt-5">
                <div className="col-md-4"></div> {/* Empty column */}
                <div className="col-md-4">
                    <PricingCard 
                        ageGroup="Camp" 
                        price="$100" 
                        duration="Duration from 9:30am to 3:30pm."
                        offer=""
                        unit="day"
                    />
                </div>
                <div className="col-md-4"></div> {/* Empty column */}
            </div>
        </div>
    );
}

export default Pricing;