import React, { useState } from 'react';

const AddStudentModal = ({ showModal, onClose, onAddStudent }) => {
    const [studentName, setStudentName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleAddStudent = () => {
        onAddStudent(studentName, phoneNumber);
        setStudentName('');
        setPhoneNumber('');
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Student</h5>
                        <button type="button" className="close" onClick={onClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Name:</label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={studentName}
                                onChange={e => setStudentName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone Number:</label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleAddStudent}
                        >
                            Add
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddStudentModal;
