import React from 'react';

const CardComponent = ({ title, value, unit }) => (
    <div className="col-md-3">
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{unit}{value}</p>
            </div>
        </div>
    </div>
);

export default CardComponent;
