
function Teacher({ imageSrc, altText, name, description }) {
    return (
        <div class="col-lg-6 d-flex flex-column align-items-center text-center">
            <img class="rounded-circle" src={imageSrc} alt={altText} width="140" height="140" />
            <h2 class="fw-normal">{name}</h2>
            <p>{description}</p>
        </div>
    );
}

export default Teacher;