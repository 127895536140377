import React, { useState, useEffect } from 'react';
import DashNavbar from './DashNavbar';
import StudentsTable from './StudentsTable';
import './style/Dashboard.css';
import AddStudentModal from './AddStudentModal';
import CardComponent from './CardComponent';
import useStudentData from './useStudentData';
import AmountQuerySection from './AmountQuerySection';
import { handleAddStudent, handleDeleteStudent, handleQueryAmount, validateToken } from './StudentAPI';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [showModal, setShowModal] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const PROTOCOL = process.env.REACT_APP_LOGIN_PROTOCOL;
    const HOST_NAME = process.env.REACT_APP_LOGIN_HOST;
    const backendURL = `${PROTOCOL}://${HOST_NAME}`;
    const { data, students, fetchAllStudents } = useStudentData(backendURL);
    const navigate = useNavigate();

    const onAddStudent = (name, phoneNumber) => {
        handleAddStudent(name, phoneNumber, fetchAllStudents);
    };

    const onDeleteStudent = async (studentId, studentName) => {
        handleDeleteStudent(studentId, studentName, fetchAllStudents);
    };

    const handleValidateToken = async (token) => {
        const success = await validateToken(token);
        if (!success) {
            console.log('Invalid token');
            localStorage.removeItem('token');
            navigate('/login');
        }
    };

    const onQueryAmount = async (startDate, endDate, queryType) => {
        const result = await handleQueryAmount(startDate, endDate, queryType);
        return result;
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            handleValidateToken(token);
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const filteredStudents = students.filter(student => showArchived || !student.archived);

    return (
        <div>
            <DashNavbar />
            <div className="container mt-5 dashboard">
                <div className="row">
                    <CardComponent title="Total Students" value={data.totalStudents} />
                    <CardComponent title="Total Due" value={data.totalDue} unit="$" />
                    <CardComponent title="Total Paid" value={data.totalPaid} unit="$" />
                    <CardComponent title="Total Unpaid Due" value={data.totalUnpaidDue} unit="$" />
                </div>

                <AmountQuerySection onQuery={onQueryAmount} />

                <button className="btn btn-primary mt-4" onClick={() => setShowModal(true)}>
                    Add Student
                </button>

                <button className="btn btn-secondary mt-4 ml-2" onClick={() => setShowArchived(!showArchived)}>
                    {showArchived ? 'Hide Archived' : 'Show Archived'}
                </button>

                <StudentsTable
                    students={filteredStudents}
                    onDeleteStudent={onDeleteStudent}
                    fetchAllStudents={fetchAllStudents}
                    showArchived={showArchived}
                />

                <AddStudentModal 
                    showModal={showModal} 
                    onClose={() => setShowModal(false)} 
                    onAddStudent={onAddStudent} 
                />
            </div>
        </div>
    );
}

export default Dashboard;
