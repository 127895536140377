import React, { useState } from 'react';

const AmountQuerySection = ({ onQuery }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [queryType, setQueryType] = useState('paid');
    const [queryResult, setQueryResult] = useState(0);

    const handleQuery = async () => {
        console.log("Query button clicked");
        console.info("Start Date: " + startDate);
        console.info("End Date: " + endDate);
        console.info("Type: " + queryType);

        var result = await onQuery(startDate, endDate, queryType);
        if(result == null)
        {
            result = 0;
        }
        setQueryResult(result);
    };

    return (
        <div className="amount-query-section">
            <div className="form-group">
                <label htmlFor="startDate">Start Date:</label>
                <input 
                    type="date" 
                    id="startDate" 
                    value={startDate} 
                    onChange={(e) => setStartDate(e.target.value)} 
                    className="form-control" 
                />
            </div>
            <div className="form-group">
                <label htmlFor="endDate">End Date:</label>
                <input 
                    type="date" 
                    id="endDate" 
                    value={endDate} 
                    onChange={(e) => setEndDate(e.target.value)} 
                    className="form-control" 
                />
            </div>
            <div className="form-group">
                <label htmlFor="queryType">Query Type:</label>
                <select 
                    id="queryType" 
                    value={queryType} 
                    onChange={(e) => setQueryType(e.target.value)} 
                    className="form-control"
                >
                    <option value="paid">Paid</option>
                    <option value="taught">Taught</option>
                </select>
            </div>
            <button className="btn btn-primary" onClick={handleQuery}>Query Amount</button>

            {
                <div className="query-result mt-3">
                    <h4>Total Amount: ${queryResult}</h4>
                </div>
            }
        </div>
    );
};

export default AmountQuerySection;
