import React, { useState } from 'react';
import StudentTransactionsModal from './StudentTransactionsModal';
import { getTransactionsForStudentAPI, handleArchiveStudent } from './StudentAPI';

const StudentsTable = ({ students, onDeleteStudent, fetchAllStudents, showArchived }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

    // Filter students based on the showArchived prop
    const displayedStudents = students.filter(student => showArchived || !student.archived);

    const sortedStudents = [...displayedStudents].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }

        // Secondary sort based on student's unique ID to maintain a consistent order
        if (a.student_id < b.student_id) return -1;
        if (a.student_id > b.student_id) return 1;

        return 0;
    });

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };

    const getRowStyle = (balance) => {
        if (balance > 0) {
            return 'table-danger';  // red
        } else if (balance > -100 && balance <= 0) {
            return 'table-warning';  // yellow
        }
        return '';
    };

    const [showDeleteButtons, setShowDeleteButtons] = useState(false);

    // Transactions modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({ student_id: 0, name: "" });
    const [studentTransactions, setStudentTransactions] = useState([]);

    const openModal = async (student) => {
        setSelectedStudent(student);
        const transactions = await getTransactionsForStudentAPI(student.student_id);
        setStudentTransactions(transactions);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedStudent({ student_id: 0, name: "" });
        setStudentTransactions([]);
        fetchAllStudents();
    };

    return (
        <div>
            <table className="table mt-4">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>Name {getArrow('name')}</th>
                        <th onClick={() => requestSort('due')}>Amount Due {getArrow('due')}</th>
                        <th onClick={() => requestSort('paid')}>Amount Paid {getArrow('paid')}</th>
                        <th onClick={() => requestSort('balance')}>Balance {getArrow('balance')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedStudents.map(student => (
                        <tr key={student.student_id} className={getRowStyle(student.balance)} onClick={() => openModal(student)}>
                            <td>
                                {student.name}
                                {student.archived && <span className="badge badge-secondary ml-2">Archived</span>}
                            </td>
                            <td>${student.due}</td>
                            <td>${student.paid}</td>
                            <td>${student.balance}</td>
                            <td>
                                {showDeleteButtons && (
                                    <button className="btn btn-danger btn-sm" onClick={(event) => {
                                        event.stopPropagation();
                                        onDeleteStudent(student.student_id, student.name);
                                        setShowDeleteButtons(false);
                                    }}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                    </button>
                                )}
                                <button className="btn btn-warning btn-sm ml-2" onClick={(event) => {
                                    event.stopPropagation();
                                    handleArchiveStudent(student.student_id, !student.archived, fetchAllStudents);
                                }}>
                                    <i className="fas fa-archive"></i> {student.archived ? 'Unarchive' : 'Archive'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <button className="btn btn-danger btn-sm" onClick={() => setShowDeleteButtons(!showDeleteButtons)}>
                    <i className="fas fa-trash-alt"></i> Delete Student
                </button>
            </table>

            {/* Student Transactions Modal */}
            <StudentTransactionsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                selectedStudent={selectedStudent}
                transactions={studentTransactions}
            />
        </div>
    );
};

export default StudentsTable;
