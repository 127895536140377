import React from 'react';
import Navbar from './Navbar';
import PaintingCard from './PaintingCard';  // Changed from PaintingModal to PaintingCard
import './style/paintingStore.css';

function PaintingStore() {
    const paintings = [
        {
            image: 'https://www.dropbox.com/scl/fi/y3adhwpxkgi596436oe6u/tennis.jpg?rlkey=cwvb486caxarfi3pefprobgk3&raw=1',
            price: 'Sold $2000',
            title: 'Vancouver Open',
            description: 'This acrylic masterpiece transports viewers to the Vancouver Open tennis tournament with a whimsical twist. Instead of human competitors and spectators, a diverse ensemble of animals takes center stage, each capturing the essence of the sport\'s fervor. Encircling the court, native flowers and plants quintessential to Vancouver bloom, grounding the scene in the city\'s lush environment and intertwining nature with the spirit of competition.',
            author: 'Dora Wu, Baiyu Huang, Baixiao Huang'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/c98asxd743rhvclddfm6o/The-crows.jpg?rlkey=d6wwc2vfrlhju1vn3yeuheze8&raw=1',
            price: '$500',
            title: 'My friends',
            description: 'Upon her initial arrival in Vancouver, the artist found solace not in people, but in the ever-present crows. Journeying to school, she was invariably accompanied by these enigmatic birds. In this piece, the crows serve not merely as subjects but as personifications of companionship and the unexpected ways we find connection in unfamiliar terrains',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/8jc0pjo15gqj33mv0pjib/I-can-t.jpg?rlkey=vbe6cfbx47sdk3xafhb2m7ex8&raw=1',
            price: '$200',
            title: 'I can\'t',
            description: 'Screenprint. This piece vividly illustrates the impact of our information-saturated society on the individual. Overwhelmed by the deluge, the figure appears to listening to the information using earpiece, symbolized by the crumpled paper representing the relentless barrage of excessive information.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/aps3j0xy2n6dbnsq646wv/yelling-kid.jpg?rlkey=jb1elzbjfcbh465phr8dw949s&raw=1',
            price: '$200',
            title: 'Connections',
            description: 'The artist conveys a profound sense that all things are interconnected, reaching as far as the vast expanse of the cosmos. The child depicted hails from Yunnan, China, inspired by a captivating photograph.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/sj66oxsjbmbc2cx7ddjm6/heart.jpg?rlkey=lh4kqz45vd8oapqokt7hbrhsi&raw=1',
            price: '$500',
            title: 'Heart',
            description: 'The artist crafted this piece following a personal heartbreak, capturing the essence of closure while hinting at lingering ties that still bind.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/5ay1mxhssl5k1b2t46ttx/shoes.jpg?rlkey=riylo6oktsvmtb0guc9s8i8mc&raw=1',
            price: '$100',
            title: 'We are done!',
            description: 'In this emotive piece, the artist channels the raw sentiments of a breakup. The shoes, meticulously depicted, alongside the intricate lines, symbolize the shared journey and the paths once walked together, evoking the memories and milestones of their relationship.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/iqapuuw1hu52ofanw5e81/oldman.jpg?rlkey=fhzpixde1x4q46clzhw8ll5h8&raw=1',
            price: '$50',
            title: 'Old woman',
            description: 'Old woman drawing',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/z6lq263s9la4it8af73b2/dragonfruit.jpg?rlkey=qi93fybdswrkdgal86pg92mn4&raw=1',
            price: '$50',
            title: 'Dragon Fruits',
            description: 'Inspired by her maiden experience with the vibrant yellow dragon fruits, the artist has captured not just the unique aesthetics of the fruit but also the novelty and wonder of first-time encounters. This piece is a testament to life\'s simple pleasures and the joy of discovering the unfamiliar.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/dp29b8ihz7akc6v7h9951/firstimpression.jpg?rlkey=ie10ymlq43l352xrx0lt3sr14&raw=1',
            price: 'Sold',
            title: 'First impression',
            description: 'In this evocative piece, the artist encapsulates her initial impressions of Vancouver. The figures, merging human bodies with butterfly wings, epitomize the city\'s rich tapestry of cultures and peoples. Their vibrant hues reflect the diversity, while their flight signifies the collective pursuit of the Canadian dream. Most strikingly, their heads, fashioned as First Nations totems, underscore a deep respect and acknowledgment of the indigenous roots and traditions of the land.',
            author: 'Dora Wu'
        },
        {
            image: 'https://www.dropbox.com/scl/fi/06cspf3mpgvndfu47oxw4/flowerOldCouples.jpg?rlkey=tcz94jaybsntt1arm641pugeu&raw=1',
            price: '$200',
            title: 'Assorted',
            description: 'Assorted watercolor drawings.',
            author: 'Dora Wu'
        },
    ];

    return (
        <div>
            <Navbar />
            <div className='store'>
                <div className="container mt-5">
                    <h2 className="mb-5">Paintings Store</h2>
                    <PaintingCard paintings={paintings} />
                </div>
            </div>
        </div>
    );
}

export default PaintingStore;
