import './style/react_carousel.css';
import React, { useRef, useState, useEffect } from 'react';

const Carousel = ({ images, caption }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideTimeoutRef = useRef(null);

    const autoSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        startAutoSlide();
    };

    const startAutoSlide = () => {
        if (slideTimeoutRef.current) {
            clearTimeout(slideTimeoutRef.current);
        }
        slideTimeoutRef.current = setTimeout(autoSlide, 5000);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        if (slideTimeoutRef.current) {
            clearTimeout(slideTimeoutRef.current);
        }
        startAutoSlide();
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        if (slideTimeoutRef.current) {
            clearTimeout(slideTimeoutRef.current);
        }
        startAutoSlide();
    };

    useEffect(() => {
        startAutoSlide();  // Start the auto-slide timer when the component mounts

        return () => {
            if (slideTimeoutRef.current) {
                clearTimeout(slideTimeoutRef.current);  // Clear the timeout when the component unmounts
            }
        };
    }, []);

    return (
        <div className="carousel-container">
            <div 
                className="carousel-slides" 
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <img key={index} className="carousel-image" src={image} alt={`carousel ${index}`} />
                ))}
            </div>

            <p className="fixed-caption">{caption}</p>

            <button className="carousel-button carousel-button-left" onClick={prevSlide}>←</button>
            <button className="carousel-button carousel-button-right" onClick={nextSlide}>→</button>
        </div>
    );
};

export default Carousel