import React, { useState, useEffect } from 'react';
import { addTransactionAPI, getTransactionsForStudentAPI, deleteTransactionAPI } from './StudentAPI';

const StudentTransactionsModal = ({ isOpen, onClose, selectedStudent, transactions }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'transaction_date', direction: 'ascending' });
    // update transaction list
    const [transactionList, setTransactionList] = useState(transactions);
    useEffect(() => {
        setTransactionList(transactions);
    }, [transactions]);
    const sortedTransactions = [...transactionList].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };

    // add new transaction
    const [newTransaction, setNewTransaction] = useState({transaction_date: '', amount: 0 });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewTransaction(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const addTransaction = async() => {
        
        await addTransactionAPI(selectedStudent.student_id, newTransaction.amount, newTransaction.transaction_date);

        // Clear the form
        setNewTransaction({ transaction_date: '', amount: newTransaction.amount });
        // update transaction list
        transactions = await getTransactionsForStudentAPI(selectedStudent.student_id);
        setTransactionList(transactions);

    };

    const deleteTransaction = async (transactionId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this transaction?");

        if (!confirmDelete) {
            return; 
        }
        try {
            
            await deleteTransactionAPI(transactionId);
            
            // Update the transaction list after deleting
            const updatedTransactions = await getTransactionsForStudentAPI(selectedStudent.student_id);
            setTransactionList(updatedTransactions);
        } catch (error) {
            console.error('Failed to delete transaction:', error);
        }
    };    


    return (
        <div>
            {/* Bootstrap Modal */}
            <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={isOpen ? {display: 'block'} : {}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Transactions for Student: {selectedStudent.name} <br />
                                <span className="phone-num">
                                    <br /> Phone: {selectedStudent.phone_num}
                                </span>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* Transactions table */}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th onClick={() => requestSort('transaction_id')}>ID {getArrow('transaction_id')}</th>
                                        <th onClick={() => requestSort('transaction_date')}>Date {getArrow('transaction_date')}</th>
                                        <th onClick={() => requestSort('amount')}>Amount {getArrow('amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedTransactions.map(transaction => (
                                        <tr key={transaction.transaction_id}>
                                            <td>{transaction.transaction_id}</td>
                                            <td>{transaction.transaction_date.split('T')[0]}</td>
                                            <td>${transaction.amount}</td>
                                            <td>
                                                <button className="btn btn-danger btn-sm" onClick={() => deleteTransaction(transaction.transaction_id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
    
                            {/* Add Transaction Form */}
                            <h5 className="mt-3">Add New Transaction</h5>
                            <div className="form-group">
                                <label>Date</label>
                                <input type="date" name="transaction_date" value={newTransaction.transaction_date} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Amount</label>
                                <input type="number" name="amount" value={newTransaction.amount} onChange={handleInputChange} className="form-control" />
                            </div>
                            <button className="btn btn-primary" onClick={addTransaction}>Add Transaction</button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Backdrop */}
            {isOpen && <div className="modal-backdrop show"></div>}
        </div>
    );
    
}

export default StudentTransactionsModal;
