// EventSection.js
import React, { useState } from 'react';
import GalleryModal from './GalleryModal';
import DropboxImageFetcher from './DropboxImageFetcher'; // Import the custom hook

function EventSection({ event, isPastEvent }) {
  var images = event.images_urls;

  return (
    <div className="event-section">
      <h2>{event.title}</h2>
      <h2>{event.title_zh}</h2>
      {!isPastEvent && (
        <>
          <p className='bold'>Date:</p>
          <p>{event.date}</p>
          <p className='bold'>Time:</p>
          <p>{event.time}</p>
          <p className='bold'>Price:</p>
          <p>{event.price}</p>
        </>
      )}
      <p className='bold'>Description:</p>
      <p>{event.description}</p>
      <p>{event.description_zh}</p>
      <GalleryModal images={images} />
    </div>
  );
}

export default EventSection;
