import { useState, useEffect } from 'react';

const useStudentData = (backendURL) => {
    const [data, setData] = useState({
        totalStudents: 0,
        totalDue: 0,
        totalPaid: 0,
        totalBalance: 0,
        totalUnpaidDue: 0
    });

    const [students, setStudents] = useState([]);

    const fetchAllStudents = () => {
            fetch(backendURL + '/get_students', {
                credentials: 'include' // Include credentials (cookies) in the request
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(students => {
                const fetchTransactionsPromises = students.map(student =>
                        fetch(backendURL + `/get_transactions/${student.student_id}`, {
                            credentials: 'include' // Include credentials (cookies) in the request
                        })
                        .then(response => response.json())
                        .then(transactions => {
                            let due = 0;
                            let paid = 0;
                            transactions.forEach(transaction => {
                                let amount = parseFloat(transaction.amount);
                                if (amount > 0) {
                                    due += amount;
                                } else {
                                    paid += -amount;
                                }
                            });
                            return {
                                ...student,
                                due,
                                paid,
                                balance: due - paid,
                                archived: student.archived,
                            };
                        })
                        .catch(error => {
                            console.error('There was a problem with the fetch operation:', error.message);
                        })
                );

                Promise.all(fetchTransactionsPromises).then(processedStudents => {
                    setStudents(processedStudents);

                    const totalStudents = processedStudents.length;
                    const totalDue = processedStudents.reduce((acc, student) => acc + student.due, 0);
                    const totalPaid = processedStudents.reduce((acc, student) => acc + student.paid, 0);
                    const totalBalance = processedStudents.reduce((acc, student) => acc + student.balance, 0);
                    const totalUnpaidDue = processedStudents.reduce((acc, student) => {
                        const unpaid = student.due - student.paid;
                        return unpaid > 0 ? acc + unpaid : acc;
                    }, 0);

                    setData({
                        totalStudents: totalStudents,
                        totalDue: totalDue,
                        totalPaid: totalPaid,
                        totalBalance: totalBalance,
                        totalUnpaidDue: totalUnpaidDue
                    });
                });
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    };

    useEffect(() => {
        fetchAllStudents();
    }, []);

    return { data, students, fetchAllStudents };
};

export default useStudentData;
