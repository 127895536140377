function Placeholder() {
    return (
        <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
    );
}

function Featurette({ title, subtitle, description, imgSrc, imgAlt, reverseOrder }) {
    const orderClass = reverseOrder ? 'order-md-2' : '';
    return (
        <div class="row featurette">
            <div class={`col-md-7 ${orderClass}`}>
                <h2 class="featurette-heading fw-normal lh-1">{title} <span class="text-body-secondary">{subtitle}</span></h2>
                <p class="lead">{description}</p>
            </div>
            <div class={`col-md-5 ${reverseOrder ? 'order-md-1' : ''}`}>
                {imgSrc ? <img src={imgSrc} class="featurette-image img-fluid mx-auto" alt={imgAlt} /> : <Placeholder />}
            </div>
        </div>
    );
}

export default Featurette;