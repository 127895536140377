import Teacher from './Teacher';
import Featurette from './Featurette';

function Marketing() {
    const images = 
    {
        Dora:"https://www.dropbox.com/scl/fi/joy4hmxpta11c45xf7ygk/dora.jpg?rlkey=5bj0c0q8wqq7ikgk31fxw5poa&raw=1",
        Carol:"https://www.dropbox.com/scl/fi/z6omsovwsi8nqleg140y5/carol.jpg?rlkey=zerknxwxnw0rvbsgq33123f0f&raw=1",
        Fafa: "https://www.dropbox.com/scl/fi/gylybfamqvl8ojwmhxik9/fafa.jpg?rlkey=1cbiq2c9yv6h2cnjhphlwbtya&st=2q9e4ddf&dl=0&raw=1",
        Rourou: "https://www.dropbox.com/scl/fi/lse8njhw1aju2plnp3tzl/rourou.jpg?rlkey=zhtltij6ufo6nf8dl97dppwlr&st=55rktbog&dl=0&raw=1", 
        Sally: "https://www.dropbox.com/scl/fi/x5crgtoqmrvcrnaurm1pz/sally.jpg?rlkey=1hhobtslp37jqh30mopmcvcau&st=q0m0ai7a&dl=0&raw=1",
        Baiyu:"https://www.dropbox.com/scl/fi/y1dx8yejgavwmlwhrt47p/baiyu.jpg?rlkey=rsmlxwxj39j2irs7150grz3to&raw=1",
        Baixiao:"https://www.dropbox.com/scl/fi/rm7lx26eywped2dgwchjw/baixiao.jpg?rlkey=xzvf1tdxzsruccuk2hog4fdex&st=hqtqw83b&raw=1",
        Studio:"https://www.dropbox.com/scl/fi/wcl2dl9p34tixl0hd1na2/studio_combined.jpg?rlkey=j7supnd94y3h6cnciar28n7ez&raw=1",
        Nature:"https://www.dropbox.com/scl/fi/omhnorw3jmfn2g6h0xwei/nature.jpg?rlkey=hg2dcu5hfketj9xzy5ke73nlv&raw=1",
        Kids: "https://www.dropbox.com/scl/fi/vguryuh6a1feb2c80dch0/kids.jpg?rlkey=4v26t455bzkd8xajrynpivon7&raw=1"
    }
    return (
        <div class="container marketing">
            <h1 class="text-center">Our Teachers</h1>
            <div class="row">
                <Teacher imageSrc={images.Dora} altText="Image 1" name="Dora Jiaoyu Wu" description="Dora has been a devoted art teacher since 2011, excelling in children's painting, and is currently advancing her expertise at Emily Carr University of Art + Design. Her dual role as a student and teacher showcases her commitment to continuous learning and inspiring young artists." />
                <Teacher imageSrc={images.Carol} altText="Image 2" name="Carol" description="Carol, a distinguished educator with multiple years of teaching experience, earned her B.S. in Sociology from UBC and is now furthering her art studies at Emily Carr University. Her deep-rooted passion for art, combined with her academic foundation in sociology, makes her an invaluable asset in the educational realm." />
                <Teacher imageSrc={images.Fafa} altText="Image 3" name="Fafa" description="Fafa Ruhua Cai is a popular watercolor teacher based in Vancouver. With one year of experience leading adult painting workshops and two years instructing children's art classes in the city, she brings extensive expertise to her students. As a self-taught artist who transitioned from amateur to professional illustrator, she has partnered with well-known publishing houses and creative brands. Fafa patiently shares her painting techniques with each student, skillfully adapting her instruction to individual abilities and inspiring students to uncover their innate talents." />
                <Teacher imageSrc={images.Rourou} altText="Image 4" name="Rourou" description="Roro, a former media professional turned illustrator and educator, graduated from NYU with a passion for art education and creativity. Specializing in creative cartoon figure drawing, creative art and craft workshop, her illustrations can be found in spaces like Sungiven Foods Greystone store. Her distinct teaching approach aims to guild students to find joy and self-expression through drawing."/>
                <Teacher imageSrc={images.Sally} altText="Image 5" name="Sally" description="Sally is a passionate student in Emily Carr University of Art and Design pursuing deeper exploration of art and design. She enjoys practicing various types of art such as drawing, painting, figure drawing, and graphic design." />
            </div>

            <h1 class="text-center">Our Engineers</h1>
            <div class="row">
                <Teacher imageSrc={images.Baiyu}altText="Image 3" name="Baiyu Huang" description="An adept Software Engineer with extensive experience in the autonomous driving and drone industries, as well as a background in automation and research. Proficient in machine learning, AI, and robotics, he couples his practical know-how with academic excellence, holding a B.S. and M.S. in Computer Science with a focus on Robotics from the University of Southern California." />
                <Teacher imageSrc={images.Baixiao} altText="Image 4" name="Baixiao Huang" description="Accomplished Software Engineer with a proven track record in the e-learning sector, currently pioneering advancements at Cruise, a leading autonomous driving company. Proficient in Java, C++, full-stack web application development, and embedded software. Graduated with a Bachelor's degree in Computer Engineering from the University of California, Los Angeles, he merges academic rigor with practical expertise to drive innovation in the tech space." />
            </div>
            
            <hr class="featurette-divider" />
            
            <Featurette title="Our Beautiful Studio" subtitle="" description="Located at 8531 Alexandra Rd. Kids can enjoy our art classes in the best neighbour hood." imgSrc={images.Studio} imgAlt="" />
            
            <hr class="featurette-divider" />
            
            <Featurette title="Learn to appreciate nature" subtitle="" description="We often take kids into the nature and teach them to look at colors and shapes in an artistic light." reverseOrder={true} imgSrc= {images.Nature}/>
            
            <hr class="featurette-divider" />
            
            <Featurette title="Art that kids of all ages enjoy" subtitle="" description="We teach kids of all ages. Whether they are beginner or not, they are all welcome here." imgSrc={images.Kids} />
            
            <hr class="featurette-divider" />
        </div>
    );
}

export default Marketing;