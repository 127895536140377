import MainPage from "./MainPage";
import Contact from "./Contact";
import Gallery from './Gallery';
import Dashboard from "./Dashboard/Dashboard";
import Login from './Login';
import {
    BrowserRouter as Router,
    Route,
    Routes,
  } from "react-router-dom";
import PaintingStore from "./PaintingStore";
import { Helmet } from 'react-helmet';
import EventPage from "./EventPage";

function App() {

    return (
        <div>
            <Helmet>
                <title>The First Spring Art Studio</title>
                <meta name="description" content="Art school in Vancouver" />
                <link rel="canonical" href="http://firstspringart.ca" />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/events" element={<EventPage />} />
                    <Route path="/paintingStore" element={<PaintingStore/>} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
