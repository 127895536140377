import React, { useState } from 'react';

function PaintingCard({ paintings }) {
    const [selectedPainting, setSelectedPainting] = useState(null);

    const handleContentClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div>
            <div className="row">
                {paintings.map((painting, index) => (
                    <div className="col-md-3 mb-3" key={index} onClick={() => setSelectedPainting(painting)}>
                        <div className="painting-card">
                            <img src={painting.image} alt={`painting-${index}`} className="img-thumbnail" />
                            <div className="painting-details">
                                <p className="painting-price">{painting.price}</p>
                                <p className="painting-title">Title: {painting.title}</p>
                                <p className="painting-author">Artist: {painting.author}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {selectedPainting && (
                <div className="painting-modal" onClick={() => setSelectedPainting(null)}>
                    <div className="modal-content container" onClick={handleContentClick}>
                        <div className="row">
                            <div className="col-md-8 image-container d-none d-md-block">
                                <img src={selectedPainting.image} alt="selected-painting" className="img-fluid modal-image" />
                            </div>
                            <div className="col-12 col-md-4 modal-description">
                                <h2>{selectedPainting.title}</h2>
                                <p>Artist: {selectedPainting.author}</p>
                                <p>{selectedPainting.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PaintingCard;
