function PricingCard({ ageGroup, price, duration, offer, unit }) {
    return (
        <div className="card text-center">
            <div className="card-header">
                {ageGroup}
            </div>
            <div className="card-body">
                <h5 className="card-title">{price} / {unit}</h5>
                <p className="card-text">
                    {duration}<br />
                    <strong>{offer}</strong>
                </p>
            </div>
        </div>
    );
}

export default PricingCard;