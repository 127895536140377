// Gallery.js
import React, { useState } from 'react';
import Navbar from './Navbar';
import GalleryModal from './GalleryModal';
import DropboxImageFetcher from './DropboxImageFetcher';
import './style/gallery.css';

function Gallery() {
    var imagesUrl = ['https://www.dropbox.com/scl/fi/ltr2sf9ho17q7c8ec2zs2/image0.jpg?rlkey=179ypg0rx2ntyxvfybevo0h10&raw=1', 'https://www.dropbox.com/scl/fi/xgktt31lveorgiuwd264c/image1.jpg?rlkey=l525uua5gg8v7ajfnfic0knq8&raw=1', 'https://www.dropbox.com/scl/fi/oifuuvxtck3x9u40dmu5o/image10.jpg?rlkey=bsdkk1kdxmuy36fvyky8wolj4&raw=1', 'https://www.dropbox.com/scl/fi/tyxs4wcfr4wced5jvf4wi/image11.jpg?rlkey=eimaz9d41w3zdyd1njzczfysb&raw=1', 'https://www.dropbox.com/scl/fi/vytmlb7htfth47em7mo4y/image2.jpg?rlkey=1ombba6n0fgtdv8q9epc1mroc&raw=1', 'https://www.dropbox.com/scl/fi/mdctjugr9tgm9j93c5uca/image3.jpg?rlkey=cqz2o4q666wcstcejb3m81j78&raw=1', 'https://www.dropbox.com/scl/fi/ftp9nl4fb0oevjmbjnd03/image4.jpg?rlkey=5wkzoutnonc77ry9qjm47fhze&raw=1', 'https://www.dropbox.com/scl/fi/rxd65zpx6bg7xxixcvubd/image5.jpg?rlkey=0ab08g8nji5uxa9uil2qy5sta&raw=1', 'https://www.dropbox.com/scl/fi/be8v3r46rs4zpqw8o6o8p/image6.jpg?rlkey=zl1xkfxxocd905nz8k07ozn1r&raw=1', 'https://www.dropbox.com/scl/fi/2t07kmpdbynun0rb1huqs/image7.jpg?rlkey=t7t7fyk5w1k9b5adpq3wr2h6v&raw=1', 'https://www.dropbox.com/scl/fi/4b1x7y3e7ukwb18pq504o/image8.jpg?rlkey=sfxl75wj08jlupouh1n2t7ztz&raw=1', 'https://www.dropbox.com/scl/fi/rjkb6moovkyctzxhn8wnr/image9.jpg?rlkey=r6ortkdkpgjvx2kxdcjm98glg&raw=1']
    const folderPath = '/images/Gallery'; // Replace with your Dropbox folder path
    
    return (
        <div>
            <Navbar />
            <div className='gallery'>
                <div className="container mt-5">
                    <h2 className="mb-5">Image Gallery</h2>
                    <GalleryModal images={imagesUrl} />
                </div>
            </div>
        </div>
    );
}

export default Gallery;
